import {Link} from 'react-router-dom';

export default function Footer() {
    return (<>
        <footer>
            <div id="copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="site-info float-left">
                                <p>MULTI-PLAN_SQmn70f9692025_M - All copyrights reserved © 2025 - Designed &
                                    Developed by <a href="https://mediquotes.us/" rel="nofollow">Mediquotes</a>
                                    <br/><i>(Last Updated: 11/11/2024)</i>
                                </p>
                            </div>
                            <div className="float-right">
                                <ul className="nav nav-inline" style={{marginTop: "-12px"}}>
                                    <li className="nav-item">
                                        <Link to="/privacy" className="nav-link active">Privacy Policy</Link>
                                    </li>
                                    {/* <li className="nav-item">
                                        <Link to="/partners" className="nav-link active">Business Partners</Link>
                                    </li> */}
                                    <li className="nav-item">
                                        <Link to="/terms-of-use" className="nav-link active">Terms of Services</Link>
                                    </li>
                                </ul>
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </>)
}
