import React from 'react';
import Footer from "./Footer";
import {Link} from "react-router-dom";

export default function PrivacyPolicy() {
    var BreadCrumbTitle = "Privacy Policy";
    document.title = BreadCrumbTitle;
    return (
        <>
            <header id="home" className="hero-area-2">
                <div className="overlay"></div>
                <div className="navigation">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg">
                            <div className="navbar-brand"><Link to="/"><i
                                className="lni lni-pulse css-1n3ub8g"></i>Mediquotes
                                <div style={{fontSize: "50%", color: "white"}}>A non-government entity</div>
                            </Link></div>
                            <button className="navbar-toggler" type="button" data-toggle="collapse"
                                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                <span className="toggler-icon"></span>
                                <span className="toggler-icon"></span>
                                <span className="toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item active">
                                        <Link to="/#home">Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/#features">Benefits</Link>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div className="contents text-center">
                                <h2>INSURANCE QUOTE</h2>
                                <p>Our most comprehensive standardized Medigap insurance <br className="d-none"/>
                                    plans carries additional benefits</p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div id="blog-single">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-md-10">
                            <div className="blog-post">
                                <div className="post-content">
                                    <div className="col-md-12 ">
                                        <h3>PRIVACY POLICY</h3>
                                        <p>We at mediquotes.us (the “Site”) understand that you value your privacy and
                                            we
                                            strive to respect that privacy when you are using our services. This Privacy
                                            Policy describes what information we gather from you, how we use that
                                            information, and what we do to protect it. By using the Site, you are
                                            expressly consenting to the information collection and use practices
                                            described in this Privacy Policy, as they may be amended from time to time
                                            with or without notice to you. If you do not agree with this Privacy Policy,
                                            do not use the Site. This Privacy Policy does not apply to websites
                                            maintained by other companies or organizations to which we may link, who may
                                            link to us, or who may be affiliated with us in any way. We are not
                                            responsible for the actions and privacy policies of third-party
                                            websites.</p>
                                        <h3>Information We Collect From You</h3>
                                        <p>We may collect two types of information from you: Non-Personally Identifiable
                                            Information (“Non-PII”) and Personally Identifiable Information (“PII”).</p>
                                        <h3>What is Non-PII?</h3>
                                        <p>Non-Personally Identifiable Information does not individually identify you.
                                            Rather, it is anonymous technical information about users’ visits to the
                                            Site and certain other internet properties, used to evaluate and enhance the
                                            Site’s services and to offer you products and services which may be relevant
                                            to you. Your Non-PII may be utilized individually or aggregated with other
                                            users’ Non-PII. Non-PII collected from you may include, without limitation:
                                            the IP address assigned to your computer or other device and other general
                                            location information; the type of internet browser and operating system your
                                            computer or other device is using; your time of visit to, and how long your
                                            visits lasts on, the Site; anonymous, demographic data about you, such as
                                            the potential age range or household income range which you might fall
                                            under; unique device identifiers; and other transactional information
                                            related to your use of the Site and certain other internet properties
                                            through the use of “cookies”, “web beacons”, or other tracking technologies,
                                            such as a session ID or your use of a particular online service over time
                                            and across multiple websites.</p>
                                        <h3>How is Non-PII Collected?</h3>
                                        <p>Non-PII is generally collected automatically by using the Site or other
                                            internet services, though you may control the collection of certain
                                            information, such as by setting your browser to block cookies or by opting
                                            out of certain interest-based advertising, as described more fully below, in
                                            the section titled, “How Can You Limit the Collection and Use of Your
                                            Non-PII?”.</p>
                                        <h3>Is Non-PII Reidentified?</h3>
                                        <p>Yes. If utilized in a non-aggregated way, Non-PII is associated with an
                                            individual but it is not generally linked to any PII such that the identity
                                            of that individual can be reasonably determined. However, the Site may, from
                                            time to time, utilize information collected from the Site, other web
                                            properties associated with the Site, or from third parties in order to link
                                            Non-PII with other Non-PII, or Non-PII with PII, in order to personally
                                            identify you.</p>
                                        <h3>What is PII?</h3>
                                        <p>By contrast, Personally Identifiable Information is information that does
                                            personally identify you. It includes, without limitation, your name, email
                                            address, physical address, phone number, and email address, or Non-PII that
                                            has been linked to such PII.</p>
                                        <h3>How is PII Collected?</h3>
                                        <p>In order to collect PII from you on the Site, you must submit the PII
                                            voluntarily. If you do not want us to collect such information, you should
                                            not submit it to the Site. However, doing so may restrict your ability to
                                            use the Site. Although we do not collect PII from you on the Site without
                                            your consent, we may otherwise lawfully obtain information from reputable
                                            third parties such as companies who provide publicly-available information
                                            or other Non-PII which is then linked to your PII.</p>
                                        <h3>How We Use Your Information Do We Use Your Non-PII Internally?</h3>
                                        <p>Yes. As touched on above, we utilize Non-PII in a number of ways. Typically,
                                            it is used internally in an aggregated way to analyze and report on the ways
                                            in which the Site is used and how we can improve the user experience and
                                            improve the Site’s performance and services. We may analyze an individual
                                            user’s Non-PII for the same reasons.</p>
                                        <h3>Do We Share Your Non-PII With Third Parties?</h3>
                                        <p>Yes. We also share Non-PII and our findings regarding Non-PII analysis with
                                            our agents, affiliates, and parent companies, as well as with our trusted
                                            and reputable third party vendors who provide us with various services so
                                            that we may operate the Site and provide you with the Site’s services
                                            (“Vendors”). We use commercially reasonable efforts to ensure that
                                            information shared with our Vendors is subject to a confidentiality
                                            agreement between us and the Vendor, and that it is consistent with this
                                            Privacy Policy. Our online advertising partners do not have access to or use
                                            your name, address, e-mail address, telephone number or other personally
                                            identifiable information from us, without your consent. They may, however,
                                            use persistent identifiers to anonymously track your Internet usage across
                                            other websites in their networks beyond these Sites (see the section below
                                            titled, “Do We Engage in Marketing, Interest-Based Advertising, and
                                            Retargeting?”). Such third parties may, with sufficient data from other
                                            sources, be able to personally identify you, unknown to us.</p>
                                        <h3>Do Third Parties Have Their Own Collection and Use Practices?</h3>
                                        <p>Yes. Certain Vendors provide data, analytics, and marketing services (such as
                                            Google Analytics, an ad server, or a third-party marketing partner) and may
                                            employ their own technologies for collection of information on the Site.
                                            While a Vendor may not generally implement a collection technology without
                                            our consent, we do not control the Vendors’ technologies, how they may be
                                            used, or the information they may collect, and we are not responsible for
                                            the privacy policies or the content of the Vendors. Please visit the sites
                                            of those businesses to review their privacy policies.</p>
                                        <h3>Do You Use Cookies?</h3>
                                        <p>Yes. Non-PII may be stored on your computer in the form of a “cookie”.
                                            Cookies enable the Site to “personalize” itself for each user in order to
                                            make the Site operation more efficient and user friendly. For example, a
                                            cookie may reduce the time it takes for pages to load on your computer. Our
                                            Vendors may also use cookies or other tracking technologies as described
                                            above. We or our Vendors may, for example, use cookies to track the
                                            performance of advertising services provided by our Vendors.</p>
                                        <h3>What Information Do Cookies Collect?</h3>
                                        <p>A “session” cookie will store information about your activity on the Site
                                            until the browser is closed, at which point the session cookie may be
                                            deleted. A cookie may also store information about your activity on the Site
                                            beyond the closing of the browser, such as by storing your password or other
                                            information so that you don’t have to re-enter it each time. Typically, our
                                            cookies will expire after 30 days of inactivity.</p>
                                        <h3>Do We Engage in Marketing, Interest-Based Advertising, and Retargeting?</h3>
                                        <p>Yes. We, our Vendors, or Partners (defined in the section below titled, “Do
                                            We Share Your PII With Third Parties?”) may set a cookie on your device to
                                            provide you with interest-based or “targeting” advertising. Such tactics may
                                            include tracking your use of a web service over time and across different
                                            websites, in what is known as “retargeting” which is a type of
                                            interest-based advertising. Retargeting means that after visiting the Site,
                                            third parties may deliver ads to you on other websites based on your
                                            interest in, or activity on this Site. Conversely, you may be shown ads on
                                            this Site based on your interest in, or activity on, third parties’
                                            websites. Our advertising partners do not have access to or use your PII
                                            from us, without your consent, except as otherwise provided herein. As
                                            discussed above, they may track your Internet usage across other websites
                                            and combine data from other sources to personally identify you, outside of
                                            our knowledge and control.</p>
                                        <h3>Do We Use Your PII Internally?</h3>
                                        <p>Yes. Without limiting other potential uses, we may, but are not required to
                                            use your PII in the following ways: communicate with you about your account
                                            or transactions with us and send you information about features and
                                            enhancements on or to the Site; communicate with you about changes to this
                                            Privacy Policy; send you newsletters, offers and promotions for our products
                                            and services, third party products and services, or special events by
                                            e-mail, text, or other another medium, either by us or by our Vendors;
                                            administer contests, giveaways, promotions, and polls; and detect,
                                            investigate, and prevent activities that may violate our policies or be
                                            illegal.</p>
                                        <h3>Do We Share Your PII With Third Parties?</h3>
                                        <p>Yes. We may need to share your PII with our Vendors who provide services to
                                            us so that we can operate the Site and provide the Site’s services. Such
                                            Vendors may not utilize your PII for any other purpose, and their use of
                                            your PII is subject to their confidentiality agreement with us, and their
                                            own privacy policies where applicable. We may also share, sell, rent, or
                                            license your PII to other third-party partners, sponsors, advertisers,
                                            marketers, or product or service providers that are able to provide
                                            products, services, and offers to you (“Partners”). Except as otherwise
                                            provided in this Privacy Policy, we do not sell, rent, or share your PII
                                            with unaffiliated third parties without your consent.</p>
                                        <h3>Do Third Parties Collect Your PII Directly?</h3>
                                        <p>Yes. The Site may utilize Partners to operate or deliver certain portions of
                                            the Site (“Portion”). Although the Portions may have a similar appearance
                                            as, or appear to be integrated within, the remainder of the Site,
                                            information submitted through Portions are submitted directly to Partners
                                            and not the Site, and are subject to the privacy policies and terms of use
                                            of the Partners. When you submit information or request services through
                                            Portions, you are authorizing the Partners to provide you with the products
                                            or services you have requested, and for them to, at your expense, contact
                                            you by: phone, including without limitation any prerecorded telephone call
                                            to any telephone number including without limitation any wireless telephone
                                            number; SMS text message; email; or mail. If you do not want further
                                            communication from them, please notify the person or entity that contacts
                                            you directly. We do not control the Partners’ technologies, how they may be
                                            used, or the information they may collect, and we are not responsible for
                                            the privacy policies or the content of the Partners. Please visit the sites
                                            of those businesses to review their privacy policies and terms of uses. For
                                            any legal claim, dispute, or inquiry regarding the Partners’ products or
                                            services, please contact the Partner directly.</p>
                                        <h3>May We Assign Your Information to Another Company?</h3>
                                        <p>Yes. In the event of a corporate change in control resulting from, for
                                            example, a sale to, or merger with, another entity, or in the event of a
                                            sale of assets or a bankruptcy, we may transfer any of your information to
                                            the new party in control or the party acquiring assets. Under such
                                            circumstances we would, to the extent possible, require the acquiring party
                                            to follow the practices described in this Privacy Policy, as it may be
                                            amended from time to time. Nevertheless, we cannot promise that an acquiring
                                            company or the merged company will have the same privacy practices or treat
                                            your information the same as described in this Privacy Policy.</p>
                                        <h3>Are There Other Circumstances Under Which We Might Share Your
                                            Information?</h3>
                                        <p>Yes. There are other circumstances under which we might share your
                                            information, generally when there is a legal issue or when attempting to
                                            resolve a dispute. We may disclose any of your information to third parties:
                                            when we reasonably believe we are obligated to do so by law, including
                                            without limitation, by contract, search warrant, subpoena, or court order;
                                            to investigate, prevent, or take action regarding suspected or actual
                                            prohibited activities, including without limitation, fraud, situations
                                            involving potential threats to the Site or the physical safety of any person
                                            or the public, or violations of applicable law; to protect our and third
                                            party rights; and to communicate with service providers who may assist us in
                                            responding to your inquiries, requests, or disputes.</p>
                                        <h3>Your Choices and Control Over the Collection and Use of Your
                                            Information</h3>
                                        <p>We believe you should have choices about the collection, use, and sharing of
                                            your information. Although you cannot opt-out of all data collection, you
                                            can limit the collection, use, and sharing of some of your information.</p>
                                        <h3>How Can You Limit the Collection and Use of Your PII?</h3>
                                        <p>As stated above, if you do not wish for us or third parties to collect your
                                            PII, you should not submit PII into the Site. The very nature of some of the
                                            services the Site offers requires your PII. Therefore, if you do not submit
                                            PII you may not be able to use the associated services. You may also email
                                            info@mediquotes.us to request access or changes to your PII; however, you
                                            will
                                            not be permitted to examine the PII of any other person or entity and may be
                                            required to provide us with PII to verify your identity prior to accessing
                                            any records containing information about you. We may not accommodate a
                                            request to change or delete PII information if we believe doing so would
                                            violate any law or legal requirement, or cause the information to be
                                            incorrect.</p>
                                        <h3>Opting Out of Email Marketing Communications</h3>
                                        <p>You may opt-out of receiving email marketing communications from us by
                                            sending an email to info@mediquotes.us or by following the opt-out link in
                                            the
                                            emails you receive. Upon your opt-out, please allow up to 10 business days
                                            for the opt-out to go into effect. Please note that you may not be able to
                                            opt-out of transactional communications which are required as a part of your
                                            account but which are not meant to offer you products or services. Also note
                                            that our Vendors or Partners may send you email marketing communications or
                                            advertising offers through other mediums. Your opt-out request to us may not
                                            affect whether third parties continue to market to you. In those scenarios,
                                            please contact those companies directly.</p>
                                        <h3>How Can You Limit the Collection and Use of Your Non-PII?</h3>
                                        <p>There are several ways you may limit the collection and use of your Non-PII.
                                            First, you may modify your browser’s cookie settings. Most web browsers
                                            automatically accept cookies, but, if you prefer, you can usually modify
                                            your browser setting to disable or reject cookies. If you delete your
                                            cookies or if you set your web browser to decline cookies, some features of
                                            the Site may not work or may not work as designed.</p>
                                        <h3>Browser “Do Not Track” Functionality</h3>
                                        <p>Your browser or device may include “Do Not Track” functionality. Because a
                                            “Do Not Track” compliance protocol has not yet been finalized, our
                                            information collection and disclosure practices, and the choices that we
                                            provide to customers, will continue to operate as described in this Privacy
                                            Policy, whether or not a Do Not Track signal is received.</p>
                                        <h3>Opting Out of Interest-Based Advertising</h3>
                                        <p>As described above, we, our Vendors, or our Partners may engage in what is
                                            known as interest-based or targeted advertising. You may opt out of the
                                            collection of your information for purposes of interest-based advertising.
                                            For more information about interest-based ads, please visit
                                            http://www.allaboutcookies.org/. To opt out of your web browsing information
                                            being used in this manner, please visit http://optout.aboutads.info. This
                                            opt-out process is managed and governed by industry self-regulatory bodies
                                            such as the Digital Advertising Alliance
                                            (http://digitaladvertisingalliance.org/), not by us. Note that in the
                                            opt-out process, you may opt-out of interest-based advertising on a
                                            company-by-company basis, or you may opt out of interest-based ads from all
                                            companies who have elected to participate in the program. Although some
                                            companies may provide their own, direct opt-out mechanism, this Site does
                                            not currently offer that option. If you do elect to take advantage of this
                                            opt-out, it remembers your preference by setting a cookie on your device or
                                            browser. Therefore, if you change computers or browsers, or delete cookies,
                                            you will have to opt-out again for each of the companies you previously
                                            opted out of. It is also important to note that opting-out of interest-based
                                            ads does not mean that you will no longer receive advertising, nor will it
                                            prevent the receipt of interest-based advertising from third parties that do
                                            not participate in these programs. It will, however, exclude you from
                                            interest-based advertising conducted through participating networks, as
                                            provided by their policies and choice mechanisms.</p>
                                        <h3>Governing Law and Notice to Non-U.S. Users</h3>
                                        <p>The Site is published in the United States. We use commercially reasonable
                                            efforts to protect the PII of all users of the Site. However, the Site is
                                            located in and targeted to United States citizens and our policies are
                                            directed at compliance with those laws. If you are uncertain whether this
                                            Privacy Policy conflicts with the applicable local privacy laws where you
                                            are located, you should not submit your information to the Site. If you are
                                            located outside the United States, you should be aware that information
                                            about you will be transferred to the United States. If you are located in a
                                            country outside the United States and voluntarily submit information to us,
                                            either by using the Site or entering your PII, you thereby consent to the
                                            use of such information as provided in this Privacy Policy and to the
                                            transfer of that information to, and/or storage of that information in, the
                                            United States.</p>
                                        <h3>How Your Information Is Retained and Secured</h3>
                                        <p>We take commercially reasonable security measures to protect your PII,
                                            including the use of physical, technical, and administrative controls.
                                            Please understand, however, that while we try our best to safeguard your PII
                                            once we receive it, no computer system, network, or transmission of data
                                            over the Internet can be guaranteed to be 100% secure. You need to protect
                                            the privacy of your own information. You must take precautions to protect
                                            the security of any information that you may transmit over any computer
                                            networks by using encryption, secure networks and websites, and other
                                            techniques to prevent unauthorized persons from intercepting or receiving
                                            any of your information. You are responsible for the security of your
                                            information when using unencrypted, open access or otherwise unsecured
                                            networks. Please also keep your passwords strong and secure, keep your
                                            security software and computer systems up to date using industry best
                                            practices, and always be vigilant and informed when sharing your information
                                            on the Internet. The time period for which we keep information varies
                                            according to the purpose for which it is used for. In some cases, there are
                                            legal requirements to keep data for a minimum period. Unless there is a
                                            specific legal requirement for us to keep the information, we will retain it
                                            for no longer than is necessary for the purposes for which the data was
                                            collected or for which it is to be further processed.</p>
                                        <h3>Third Party Sites</h3>
                                        <p>The Site may be linked to or from other websites, or may contain links to and
                                            advertisements for sites operated by third parties whose policies regarding
                                            the handling of your information may differ from ours. While we endeavor to
                                            associate only with reputable third parties, we cannot control every site
                                            that will link to us or the information handling practices of other parties.
                                            This Privacy Policy does not address the privacy policy or terms of use of
                                            any third-party. Please directly review the policies and agreements of other
                                            companies for their information collection and use practices.</p>
                                        <h3>Information Relating to Children</h3>
                                        <p>The Site is not intended for or directed at children under 13 years of age.
                                            We do not knowingly collect PII from children under 13 years of age. If you
                                            are under 13 years of age, do not use this Site or provide your PII to us.
                                            If we discover that a child under the age of 13 has provided us with PII, or
                                            that we have inadvertently collected such information, we will promptly
                                            delete it. If you believe that we have been provided with the PII of a child
                                            under the age of 13, please notify us immediately at info@mediquotes.us.</p>
                                        <h3>Your California Privacy Rights</h3>
                                        <p>Under California law, residents of California may request certain information
                                            about our disclosure of PII during the prior calendar year to third parties
                                            for marketing purposes. To request this information, please contact us at
                                            info@mediquotes.us</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}
